import './App.css';
import TitleBar from './components/TitleBar'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Organized from './components/pages/Organized';
import Ucsb from './components/pages/Ucsb';
import Geopic from'./components/pages/Geopic';
import DocumentTitle from 'react-document-title';

function App() {
  
  return (
    <DocumentTitle title='Juan'>
      <div className="App">
        <Router>
            <TitleBar/>
          <Switch>
            <Route exact path='/'component={Home}/>
            <Route path='/about' component={About}/>
            <Route path='/ucsb-buy-sell' component={Ucsb}/>
            <Route path='/organized' component={Organized}/>
            <Route path='/geopic' component={Geopic}/>
            <Route path='*'>
              <Redirect to ='/'/>
            </Route>
          </Switch>
        </Router>
      </div>
    </DocumentTitle>
  );
  
}

export default App;
