import React from 'react';
import './Organized.css';
import VideoPlayer from '../VideoPlayer'

function Organized(){
    return(
        <div className="organized-content">
            <div className='organized-video'>
                <VideoPlayer embedId='TlCHR0Ti-oU'/>
            </div>
            <div className="organized-text">
                Organized was a proof of concept
                webapp for populating your Google
                Calender with your class info.
                It also provided you with Google maps
                direction for walking or biking to your class.
                It was built using React. I was responsible
                for the API handling, authentication
                and the basic design. The project utilized
                Google login, map, calendar and UCSB courses APIs.
            </div>
        </div>
    );
}

export default Organized