import React from 'react';
import '../../App.css';
import './Home.css'
import headshot from '../head.png'

function Home(){
    return(
        <>
            <div className='home-content'>
                <div className='home-text'>
                    <div className='home-text-opening'>
                        Hi, I'm Juan!
                    </div>
                    <div className='home-text-body'>
                       I'm a recent Computer Science graduate  <br/>
                       from UCSB who is eager to start a fufilling <br/>
                       career. I look forward to working on interesting<br/>
                        and challenging projects.<br/>
                    </div>
                    <div className='home-headshot'>
                        <img src={headshot} alt="Headshot" className='home-headshot-img'/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Home;  