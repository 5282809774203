import './Geopic.css'
import Geopic_Login from '../Geopic.png';
import Geopic_Post from '../geopic-post.png';
import Geopic_Map from '../geopic-map.png';

function Geopic(){
    return(
        <div className='Geopic-content'>
            <div className='Geopic-text'>
                Geopic was proof of concept Android app that was similiar to Instagram but with a focus on where photos were taken.
                The project was built in Android Studio using Kotlin. It utilized Google autherization and google maps. It also
                involved using different phone part such as the camera and phone location.
            </div>
            <div className='Geopic-images'>
                <div className='Geopic-login'>
                    <img src={Geopic_Login} alt="login" />
                </div>
                <div className='Geopic-post'>
                    <img src={Geopic_Post} alt="post" />
                </div>
                <div className='Geopic-map'>
                    <img src={Geopic_Map} alt="map" />
                </div>
            </div>
        </div>
    );
}
export default Geopic