import React from 'react';
import '../../App.css';
import './About.css'
import baby from '../babysun.jpeg'

function About(){
    return(
        <div className='about-content'>
            <div className='about-body'>
                I'm easygoing and someone who loves<br/>
                to learn and experiment. My interests<br/>
                include mobile development, web development,<br/>
                spending time with my newborn son, and hiking.
            </div>
            <div className='about-baby'>
                <img src={baby} alt="Johnny and I" className='about-baby-img'/>
            </div>
        </div>
    )
}
export default About