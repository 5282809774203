import React, { Component } from 'react'
import './VideoPlayer.css';

export default class VideoPlayer extends Component {
    render() {
        return (
            <div className="video-responsive">
                <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${this.props.embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                />
            </div>
        )
    }
}
