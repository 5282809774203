import React from 'react';
import './Ucsb.css';
import ucsb_homepage from '../ucsb-homepage.png';
import ucsb_post from '../ucsb-post.png';
import ucsb_admin from '../ucsb-admin.png';

function Ucsb(){
    return(
        <div className='ucsb-content'>
            <div className='ucsb-text'>
                UCSB-Buy-Sell is Craiglist clone with an emphasis on serving UCSB students.
                Users can only make post if they are members A UCSB only Github group.
                All post are verified by an admin before being posted. The webapp was written
                in Java using the Spring Boot framework. It also uses Github autherization.
                I was responsible for the post submition process and post verification process.
            </div>
            <div className='ucsb-images'>
                <div className='ucsb-homepage'>
                    <img src={ucsb_homepage} alt="ucsb-buy-sell homepage" width='500px'/>
                </div>
                <div className='ucsb-image-bottom'>
                    <div className='ucsb-post'>
                        <img src={ucsb_post} alt="post page" width='245px'/>
                    </div>
                    <div className='ucsb-admin'>
                        <img src={ucsb_admin} alt="admin page" width='245px'/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Ucsb