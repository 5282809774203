import React, { Component } from 'react'
import './TitleBar.css'
import { Link } from 'react-router-dom';


export default class TitleBar extends Component {

    constructor(){
        super();
        this.state = {
            showMenu:false,
        }
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    };

    showMenu(event){
        event.preventDefault();
        if(this.state.showMenu==false){
            this.setState({
                showMenu:true,
            });
        }
        else{
            this.setState({
                showMenu:false,
            });
        }
        
    }

    closeMenu(event){
        event.preventDefault();
        this.setState({
            showMenu:false,
        });
    }
    
    render() {
        return (
            <div className="titlebar">
                <div className="titlebar-text">
                    Juan Gonzalez
                </div>
                <ul className="titlebar-options">
                    <li className="titlebar-home" onClick={this.closeMenu}>
                        <Link to='/' className='titlebar-link'>
                            Home
                        </Link>
                    </li>
                    <li className="titlebar-projects" onClick={this.showMenu}>
                        <div className='titlebar-link'>
                            Projects
                        </div>
                    </li>
                    <li className="titlebar-about" onClick={this.closeMenu}>
                        <Link to='about' className='titlebar-link'>
                            About Me
                        </Link>
                    </li>
                </ul>
                {
                    this.state.showMenu ? (
                        <div className='titlebar-dropdown-container'>
                            <ul className='titlebar-dropdown'>
                                <li className='titlebar-dropdown-li' onClick={this.closeMenu}>
                                    <Link to='ucsb-buy-sell' className='titlebar-link'>
                                        UCSB Buy Sell
                                    </Link>
                                </li>
                                <li className='titlebar-dropdown-li' onClick={this.closeMenu}>
                                    <Link to='organized' className='titlebar-link'>
                                        Organized
                                    </Link>
                                </li>
                                <li className='titlebar-dropdown-li' onClick={this.closeMenu}>
                                    <Link to='geopic' className='titlebar-link'>
                                        GeoPic
                                    </Link> 
                                </li>
                            </ul>
                        </div>
                    )
                    :(
                        null
                    )
                }
            </div>
        )
    }
}
